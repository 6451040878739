.App {
      text-align: center;
}

.App-logo {
      height: 40vmin;
      pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
      .App-logo {
            animation: App-logo-spin infinite 20s linear;
      }
}

.App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
}

.App-link {
      color: #61dafb;
}

@keyframes App-logo-spin {
      from {
            transform: rotate(0deg);
      }
      to {
            transform: rotate(360deg);
      }
}

.product-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 10px;
}

.draggable-item {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      background-color: #FFF;
      cursor: grab;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      display: flex;
      gap: 20px;
}

.draggable-item img {
      width: 80px;
}

.draggable-row {
      cursor: all-scroll;
}

.draggable-row:active {
      cursor: all-scroll;
}
